<template>
  <loader v-if="loading" />

  <section v-else id="events" class="route">
    <!-- Heading -->
    <h1 class="title">
      <span>Events</span>
      <hr class="divider divider--vertical" />
      <i class="fas fa-arrow-right grey-light--text"></i>
      <hr class="divider divider--vertical" />
      <span class="accent--text">{{ routeName }}</span>
    </h1>

    <hr class="divider grey-light" />

    <!-- Registrations -->
    <registrations-list
      v-if="$route.name === 'Classes'"
      class="route-content"
      list-type="Event"
    />

    <template v-if="$route.name === 'Events'">
      <schedule-list class="route-content" :schedule="eventRegistrations" />

      <schedule-list
        class="route-content"
        v-if="!isStudentUser"
        assignment-list
        :schedule="assignedEvents"
      />
    </template>

    <!-- Active Route -->
    <router-view></router-view>
  </section>
</template>

<script>
/* eslint-disable no-undef */
import { routeName } from "../models/permissions";
import Loader from "../components/Loader.vue";
import RegistrationsMixin from "../components/mixins/registrations.mixin";
import ScheduleList from "../components/ScheduleList.vue";
import SubrouteManagerMixin from "../components/mixins/subroute-manager.mixin";
import RegistrationsList from "../components/RegistrationsList.vue";

export default {
  components: { Loader, ScheduleList, RegistrationsList },

  name: "Events",

  mixins: [RegistrationsMixin, SubrouteManagerMixin],

  data: () => ({ events: [], loading: true }),

  computed: {
    routeName() {
      return routeName(this.$route.name) || "Home";
    },
    subroutes() {
      return [
        { name: "ListEvents", icon: "fas fa-megaphone grey-light--text" },
        { name: "Events", icon: "fas fa-calendar-check grey-light--text" },
        { name: "CreateEvent", icon: "fas fa-plus-circle success--text" }
      ];
    }
  },

  mounted() {
    document.title = `Events • ${APP_ORG} Admin`;
    this.broadcastSubroutes();
    this.loading = false;
  }
};
</script>
